<template>
    <div class="notifications-drawer" data-testid="notifications-drawer">
        <Drawer placement="left" :transfer="false" :value="isVisible" width="400px" @on-close="close">
            <div class="notifications-drawer__header">
                <div class="notifications-drawer__header-title">Notifications</div>
                <p class="notifications-drawer__header-desc">
                    When the ads are ready, we'll send you the link by email, and it'll also appear here.
                </p>
                <hox-tabs color="tertiary" size="small">
                    <hox-tabs-item
                        :is-active="activeNotificationTab === 'in-progress'"
                        @click="changeNotificationsTab('in-progress')"
                    >
                        In Progress
                    </hox-tabs-item>
                    <hox-tabs-item
                        :is-active="activeNotificationTab === 'completed'"
                        @click="changeNotificationsTab('completed')"
                    >
                        Completed
                    </hox-tabs-item>
                </hox-tabs>
            </div>
            <div v-if="activeNotificationTab === 'in-progress'" class="notifications-drawer__content">
                <hox-alert v-if="hasErrorLoadingMyJobsByStatus" margin-bottom="none" size="small" type="danger">
                    <template #title>
                        There was an unexpected error while attempting to get your background jobs.
                    </template>
                    <template #content>
                        <p>Hopefully it is just a temporary issue and it should work if you retry in a few moments.</p>
                    </template>
                    <template #actionItems>
                        <Button :loading="isLoadingMyJobsByStatus > 0" type="primary" @click="runMyJobsByStatusQuery">
                            Retry
                        </Button>
                    </template>
                </hox-alert>
                <div v-else-if="notifications.length === 0" class="notifications-drawer__empty-message-wrapper">
                    <hox-empty-message type="all-clear">
                        <template #title>There are no notifications</template>
                    </hox-empty-message>
                </div>
                <div v-for="notification of notifications" :key="notification.id">
                    <flashtalking-download-job-notification-item
                        v-if="
                            notification.type === NotificationTypes.FlashtalkingDownloadExcel ||
                            notification.type === NotificationTypes.FlashtalkingDownloadAssets
                        "
                        :notification="notification"
                        :show-abort-button="true"
                    />
                    <download-notification-item
                        v-if="notification.type === NotificationTypes.Download"
                        :notification="notification"
                        :show-abort-button="true"
                    />
                    <download-job-notification-item
                        v-else-if="notification.type === NotificationTypes.DownloadJob"
                        :notification="notification"
                        :show-abort-button="true"
                    />
                    <publish-notification-item
                        v-else-if="
                            notification.type === NotificationTypes.Publish ||
                            notification.type === NotificationTypes.Unpublish
                        "
                        :notification="notification"
                    />
                    <generate-report-notification-item
                        v-else-if="notification.type === NotificationTypes.GenerateReport"
                        :notification="notification"
                    />

                    <background-update-notification-item
                        v-else-if="notification.type === NotificationTypes.BackgroundUpdate"
                        :notification="notification"
                    />
                </div>
            </div>
            <div
                v-if="activeNotificationTab === 'completed'"
                class="notifications-drawer__content"
                @scroll="handleScroll"
            >
                <hox-alert v-if="hasErrorLoadingMyJobsByStatus" margin-bottom="none" size="small" type="danger">
                    <template #title>
                        There was an unexpected error while attempting to get your background jobs.
                    </template>
                    <template #content>
                        <p>Hopefully it is just a temporary issue and it should work if you retry in a few moments.</p>
                    </template>
                    <template #actionItems>
                        <Button :loading="isLoadingMyJobsByStatus > 0" type="primary" @click="runMyJobsByStatusQuery">
                            Retry
                        </Button>
                    </template>
                </hox-alert>
                <div v-else-if="finishedJobs.length === 0" class="notifications-drawer__empty-message-wrapper">
                    <hox-empty-message type="all-clear">
                        <template #title>There are no finished download jobs.</template>
                    </hox-empty-message>
                </div>
                <div v-for="(notification, index) of finishedJobs" :key="notification.id + index">
                    {{ notification.name }}
                    <flashtalking-download-job-notification-item
                        v-if="
                            notification.type === NotificationTypes.FlashtalkingDownloadExcel ||
                            notification.type === NotificationTypes.FlashtalkingDownloadAssets
                        "
                        :notification="notification"
                        :is-job-finished="true"
                        @notificationDeleted="changeNotificationsTab('completed')"
                    />
                    <download-job-notification-item
                        v-else-if="notification.type === NotificationTypes.DownloadJob"
                        :notification="notification"
                        :is-job-finished="true"
                        @notificationDeleted="changeNotificationsTab('completed')"
                    />
                </div>
            </div>
            <div
                v-if="notifications.length > 0 && activeNotificationTab === 'in-progress'"
                class="notifications-drawer__footer"
                @click="clearItemsNotPendingOrInProgress"
            >
                Clear all notifications
            </div>
            <div
                v-if="finishedJobs.length > 0 && activeNotificationTab === 'completed'"
                class="notifications-drawer__footer"
                @click="clearAllNotifications"
            >
                Clear all notifications
            </div>
        </Drawer>
    </div>
</template>

<script>
import jobsByIdQuery from "@/apollo/queries/v2/JobsById.gql";
import myJobsByStatusQuery from "@/apollo/queries/v2/MyJobsByStatus.gql";
import DownloadNotificationItem from "@/components/Notifications/DownloadNotificationItem";
import DownloadJobNotificationItem from "@/components/Notifications/DownloadJobNotificationItem";
import PublishNotificationItem from "@/components/Notifications/PublishNotificationItem";
import GenerateReportNotificationItem from "@/components/Notifications/GenerateReportNotificationItem";
import BackgroundUpdateNotificationItem from "@/components/Notifications/BackgroundUpdateNotificationItem";
import FlashtalkingDownloadJobNotificationItem from "@/components/Notifications/FlashtalkingDownloadJobNotificationItem";
import { JobStatus, JobStatusToNotificationStatus, JobNames } from "@/enums/jobs";
import { NotificationTypes } from "@/enums/notifications";
import { JobsAction } from "@/store/modules/jobs";
import { NotificationsAction } from "@/store/modules/notifications";
import completedJobsQuery from "@/apollo/queries/CompletedJobs.gql";
import deleteJobs from "@/apollo/mutations/DeleteJobs.gql";

export default {
    components: {
        BackgroundUpdateNotificationItem,
        DownloadNotificationItem,
        DownloadJobNotificationItem,
        GenerateReportNotificationItem,
        PublishNotificationItem,
        FlashtalkingDownloadJobNotificationItem
    },

    data() {
        return {
            hasErrorLoadingMyJobsByStatus: false,
            isLoadingMyJobsByStatus: 0,
            activeNotificationTab: "in-progress",
            finishedJobs: [],
            finishedJobsLimit: 0,
            finishedJobsSkip: 10,
            allowLoadingMoreFinishedJobs: true,
            isLoadingMoreFinishedJobs: false
        };
    },

    computed: {
        isAuthorized() {
            return this.$store.state.auth.isAuthorized;
        },

        isVisible() {
            return this.$store.state.notifications.drawerIsVisible;
        },

        jobIdsToQuery() {
            return Object.keys(this.$store.state.jobs.notificationIdsByActiveJobId);
        },

        notifications() {
            return this.$store.state.notifications.items;
        }
    },

    watch: {
        $route() {
            this.close();
        }
    },

    created() {
        this.NotificationTypes = NotificationTypes;
    },

    methods: {
        changeNotificationsTab(tab) {
            this.activeNotificationTab = tab;
            if (tab === "completed") {
                this.isLoadingMoreFinishedJobs = true;
                this.finishedJobs = [];
                this.finishedJobsLimit = 0;
                this.allowLoadingMoreFinishedJobs = true;
                this.$apollo.queries.completedJobs.refetch();
            }
        },

        async clearAllNotifications() {
            try {
                const response = await this.$apollo.mutate({
                    mutation: deleteJobs
                });
                this.finishedJobs = [];
                this.$snackbar.success("All notifications have been cleared");
                return response;
            } catch (error) {
                this.$snackbar.error(error);
                throw error;
            }
        },

        clearItemsNotPendingOrInProgress() {
            this.$store.dispatch(NotificationsAction.ClearItemsNotPendingOrInProgress);
        },

        clearCompletedNotifications() {
            this.$store.dispatch(NotificationsAction.clearCompletedItems);
        },

        close() {
            this.$store.dispatch(NotificationsAction.SetDrawerIsVisible, false);
        },

        runMyJobsByStatusQuery() {
            this.$apollo.queries.myJobsByStatus.refetch();
        },

        getSnackBarMessageByJob(job) {
            const jobResultMessages = {
                [JobNames.UnpublishXmlFeed]: {
                    success:
                        "The ads has been removed from feeds. You can find the updated feeds in your notifications",
                    error: job.message
                },
                [JobNames.PublishXmlFeed]: {
                    success:
                        "The ads has been published to feeds. You can find the updated feeds in your notifications",
                    error: job.message
                },
                [JobNames.AddMetadataValue]: {
                    success: "The ads has been updated",
                    error: job.message
                },
                [JobNames.RemoveMetadataValue]: {
                    success: "The ads has been updated",
                    error: job.message
                }
            };
            if (jobResultMessages[job.name]) {
                return jobResultMessages[job.name];
            }
            if (job.status === JobStatus.ABORTED) {
                return {
                    error: "Your job was successfully aborted"
                };
            }
            return {
                success: "Your ads are ready to be downloaded. You can find a download link in your notifications",
                error: "There was an unexpected error while preparing ads for download"
            };
        },
        getNotificationTypeByJob(job) {
            const jobNotificationMap = {
                [JobNames.UnpublishXmlFeed]: NotificationTypes.Unpublish,
                [JobNames.PublishXmlFeed]: NotificationTypes.Publish,
                [JobNames.AddMetadataValue]: NotificationTypes.BackgroundUpdate,
                [JobNames.RemoveMetadataValue]: NotificationTypes.BackgroundUpdate,
                [JobNames.PUBFT_DOWNLOAD_EXCEL]: NotificationTypes.FlashtalkingDownloadExcel,
                [JobNames.PUBFT_DOWNLOAD_ASSETS]: NotificationTypes.FlashtalkingDownloadAssets
            };
            return jobNotificationMap[job.name] || NotificationTypes.DownloadJob;
        },

        handleScroll(event) {
            const scrollContainer = event.target;
            const { scrollTop, clientHeight, scrollHeight } = scrollContainer;

            if (scrollTop + clientHeight >= scrollHeight) {
                if (!this.isLoadingMoreFinishedJobs && this.allowLoadingMoreFinishedJobs) {
                    this.isLoadingMoreFinishedJobs = true;
                    this.loadMoreFinishedJobs();
                }
            }
        },

        loadMoreFinishedJobs() {
            this.finishedJobsLimit += 1;
            this.$apollo.queries.completedJobs.refetch();
        }
    },

    apollo: {
        myJobsByStatus: {
            query: myJobsByStatusQuery,
            fetchPolicy: "no-cache",
            loadingKey: "isLoadingMyJobsByStatus",
            skip() {
                return !this.isAuthorized;
            },
            variables() {
                return {
                    status: [JobStatus.PENDING, JobStatus.IN_PROGRESS]
                };
            },
            async result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingMyJobsByStatus = true;
                    return;
                }
                this.hasErrorLoadingMyJobsByStatus = false;
                const notificationIdsWithJobIds = await Promise.all(
                    data.myJobsByStatus.map(async job => {
                        /*
                            In theory we should never have a notification for any job at this point.
                            However, vue-apollo is an interesting beast that occasionally does unexpected
                            things so we're going to err on the side of caution and do a check so that we
                            do not end up with multiple notifications per job.
                        */
                        let notificationId = this.$store.state.jobs.notificationIdsByActiveJobId[job._id];
                        if (notificationId === undefined) {
                            notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                                message: job.message,
                                status: JobStatusToNotificationStatus[job.status],
                                type: this.getNotificationTypeByJob(job),
                                job
                            });
                        }
                        return {
                            jobId: job._id,
                            notificationId
                        };
                    })
                );
                notificationIdsWithJobIds.forEach(notificationIdWithJobId => {
                    this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, notificationIdWithJobId);
                });
            }
        },

        jobs: {
            query: jobsByIdQuery,
            fetchPolicy: "no-cache",
            pollInterval: 5000,
            skip() {
                return this.jobIdsToQuery.length === 0 || !this.isAuthorized;
            },
            variables() {
                return {
                    jobIds: this.jobIdsToQuery
                };
            },
            result({ data, error }) {
                if (error) {
                    /*
                        We don't need to do any error handling here as the request should retry
                        again in a short amount of time due to the pollInterval.
                    */
                    return;
                }

                data.jobs.forEach(job => {
                    const notificationId = this.$store.state.jobs.notificationIdsByActiveJobId[job._id];
                    this.$store.dispatch(NotificationsAction.Update, {
                        downloadLink: job.payload.zipUrl,
                        id: notificationId,
                        message: job.message,
                        status: JobStatusToNotificationStatus[job.status],
                        job
                    });
                    if (job.status !== JobStatus.PENDING && job.status !== JobStatus.IN_PROGRESS) {
                        const message = this.getSnackBarMessageByJob(job);
                        if (job.status === JobStatus.FAILED || job.status === JobStatus.ABORTED) {
                            this.$snackbar.error(message.error);
                        } else {
                            this.clearCompletedNotifications();
                            this.$snackbar.success(message.success);
                        }
                        /*
                            If the job is no longer in progress or pending then we no longer want
                            to poll it for an updated status, so we remove the job reference from the
                            object that we're using to track active jobs.
                        */
                        this.$store.dispatch(JobsAction.DeleteNotificationIdByActiveJobId, job);
                    }
                });
            }
        },

        completedJobs: {
            query: completedJobsQuery,
            fetchPolicy: "no-cache",
            skip() {
                return !this.isAuthorized;
            },
            variables() {
                return {
                    limit: this.finishedJobsSkip,
                    skip: this.finishedJobsSkip * this.finishedJobsLimit
                };
            },

            result({ data, error }) {
                if (error) {
                    this.$snackbar.error("There was an unexpected error while attempting to get your background jobs.");
                    return;
                }
                if (data.completedJobs.length < this.finishedJobsSkip) {
                    this.allowLoadingMoreFinishedJobs = false;
                }
                const updatedCompleteJobs = data.completedJobs.map(obj => {
                    let status = "completed";
                    let type = "download-job";
                    if (obj.failed) {
                        status = "failed";
                    }
                    if (obj.aborted) {
                        status = "aborted";
                    }
                    if (obj.completed) {
                        status = "completed";
                    }
                    if (obj.progress) {
                        status = "in-progress";
                    }
                    if (
                        obj.name === NotificationTypes.FlashtalkingDownloadAssets ||
                        obj.name === NotificationTypes.FlashtalkingDownloadExcel
                    ) {
                        type = obj.name;
                    }
                    const newObj = {
                        message: obj.message,
                        status,
                        type,
                        job: obj,
                        id: obj._id,
                        downloadLink: obj.payload?.zipUrl
                    };

                    return newObj;
                });
                this.isLoadingMoreFinishedJobs = false;
                this.finishedJobs = [...this.finishedJobs, ...updatedCompleteJobs];
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.notifications-drawer {
    .ivu-drawer-mask,
    .ivu-drawer-wrap {
        z-index: $zindex-notifications-drawer;
    }

    .ivu-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100%;
    }

    .ivu-drawer-content {
        box-sizing: content-box;
        border-left: $campaign-vertical-nav-width solid $cmp-light-bg-color;
    }
}

.notifications-drawer__content {
    flex: 1;
    overflow: auto;
}

.notifications-drawer__empty-message-wrapper {
    padding: $spacing-large;
}

.notifications-drawer__footer {
    background: $white;
    cursor: pointer;
    flex: 0;
    font-size: $font-size-base;
    font-weight: bold;
    transition: background 0.2s ease-in-out;
    text-align: center;
    margin: 10px;
    border: 1px solid var(--wpp-primary-color-500);
    padding: 10px;
    border-radius: 10px;
    text-transform: none;
    color: var(--wpp-primary-color-500);

    &:hover {
        background: $grey2;
    }
}

.notifications-drawer__header {
    border-bottom: $border-width-base solid $cmp-light-border-color;
    padding: $spacing-large 0 0 0;

    &-title {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        padding: 0 15px 5px;
    }
    &-desc {
        color: #4d5358;
        font-size: 14px;
        line-height: 22px;
        padding: 0 15px 20px;
    }
}
</style>
